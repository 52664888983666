import { BUILD_ENV, BUILD_DEPLOYED_VERSION } from './buildConfig';

export const DEFAULT_SOURCE_ID = 0;
export const GOODSAM_SOURCE_ID = 102565;
export const BOAT_US_SOURCE_ID = 101397;
export const BOAT_ZON_SOURCE_ID = 101392;
export const BOAT_TRADER_SOURCE_ID = 101458;
export const YACHTWORLD_SOURCE_ID = 101442;
export const BOATSDOTCOM_SOURCE_ID = 101460;
export const BOATWIZARD_SOURCE_ID = 108653;
export const RVSHARE_SOURCE_ID = 802570;
export const LENDINGTREE_SOURCE_ID = 601882;
export const PRIORITY_RV_NETWORK_SOURCE_ID = 202736;
export const SUN_COUNTRY_SOURCE_ID = 301568;
export const KNOT10_SOURCE_ID = 301579;
export const CRUISING_YACHT_SOURCE_ID = 301476;
export const JEFF_BROWN_YACHTS_SOURCE_ID = 301475;
export const FIRESTORE_TEST_UPLOADER_SOURCE_ID = 302476;

export const LOAN_AMOUNT_FOR_FINANCIAL_STATEMENT = 150000;

export const OPERATION_HOURS_DEFAULT = 'Mon - Fri: 8:30am - 5pm EST';

export const DEFAULT_KAMELEOON_SITEKEY = 'uk33jcty1m';

export const TRIDENT_KAMELEOON_GCP_API_KEY = '6b3c3393-dfb7-4d10-997e-5c480d83a25c';

export const PROD_KAMELEOON_GCP_FUNCTION_URL = `https://us-central1-trident-funding.cloudfunctions.net/loadKameleoonData?apiKey=${TRIDENT_KAMELEOON_GCP_API_KEY}`;
export const STAGE_KAMELEOON_GCP_FUNCTION_URL = `https://us-central1-stage-trident.cloudfunctions.net/loadKameleoonData?apiKey=${TRIDENT_KAMELEOON_GCP_API_KEY}`;

export const PROD_TRIDENT_API_URL = 'https://api.tridentfunding.com';
export const QA_TRIDENT_API_URL = 'https://api.qa.tridentfunding.com';

export const BOATTRADER_LEAD = 'boattrader';
export const SALESFORCE_LEAD = 'salesforce';

export const PROD_BASE_SALESFORCE_URL = 'https://boatsgroup.lightning.force.com';
export const QA_BASE_SALESFORCE_URL = 'https://boatsgroup--skyloanqa.sandbox.lightning.force.com';

export const DEFAULT_FAVICON_URL = '/static/trident/logo-tf.png';
export const DEFAULT_LOGO_URL = '/static/trident/logo-head-mobile.png';
export const SOURCE_FAVICONS_FIREBASE_CLOUD_STORAGE_FOLDER = 'trident-funnel-favicons/';
export const SOURCE_LOGOS_FIREBASE_CLOUD_STORAGE_FOLDER = 'trident-funnel-logos/';
export const MIN_TIME_TO_CREATE_NEW_GENERIC_LEAD = 7 * 24 * 60 * 60 * 1000; // 7 days
export const INIT_DATA_FIREBASE_LOGO_URL =
  'https://firebasestorage.googleapis.com/v0/b/stage-trident.appspot.com/o/trident-funnel-logos%2Finitdata-default-logo.png?alt=media&token=80b0a815-c7e9-4db1-b70f-8f057b195cd1';
export const INIT_DATA_FIREBASE_FAVICON_URL =
  'https://firebasestorage.googleapis.com/v0/b/stage-trident.appspot.com/o/trident-funnel-favicons%2Finitdata-default-favicon.png?alt=media&token=02a1a682-30c1-4a62-aa25-80f5ecb9da2b';
export const DEFAULT_THEME = {
  colors: {
    // Default Button
    defaultButtonBackground: '#FFF',
    defaultButtonBorder: '#D9D9D9',
    defaultButtonColor: '#384A68',
    defaultButtonHoverBackground: '#4972C9',
    defaultButtonHoverBorder: '#4972C9',
    defaultButtonHoverColor: '#FFF',

    // Primary Button
    primaryButtonBackground: '#4972c9',
    primaryButtonBorder: '#4972c9',
    primaryButtonText: '#ffffff',
    primaryButtonHoverBackground: '#1944a5',
    primaryButtonHoverBorder: '#1944a5',
    primaryButtonHoverText: '#ffffff',

    // Login Button
    loginButtonBackground: '#18223b',
    loginButtonBorder: '#ffffff',
    loginButtonText: '#ffffff',
    loginButtonHoverBackground: '#ffffff',
    loginButtonHoverBorder: '#ffffff',
    loginButtonHoverText: '#18223b',

    // Radio Button
    radioButtonSelectedBackground: '#5CB85C33',
    radioButtonSelectedBorder: '#5CB85C',

    // Other
    footer: '#18223b',
    header: '#18223b',
    reviewStepHeader: '#3a4c68',
    sectionBar: '#bdc3c7',
    selectionBackground: '#384a68',
    progressBar: '#5CB85C',

    // StepFooter
    stepFooterBackground: '#f5fafe',
    stepFooterBorder: '#c4c8cb',
    stepFooterIconColor: '#2566b0',
  },
  logo: DEFAULT_LOGO_URL,
  marketingWebsiteUrl: 'https://www.tridentfunding.com/',
  showMemberField: false,
  favicon: DEFAULT_FAVICON_URL,
};

export const THEME_CSS_VARS_RELATION: [string, string][] = [
  // Default Button
  ['default-button-background-color', 'defaultButtonBackground'],
  ['default-button-border-color', 'defaultButtonBorder'],
  ['default-button-text-color', 'defaultButtonColor'],
  ['default-button-hover-background-color', 'defaultButtonHoverBackground'],
  ['default-button-hover-border-color', 'defaultButtonHoverBorder'],
  ['default-button-hover-text-color', 'defaultButtonHoverColor'],

  // Primary Button
  ['primary-button-background-color', 'primaryButtonBackground'],
  ['primary-button-border-color', 'primaryButtonBorder'],
  ['primary-button-text-color', 'primaryButtonText'],
  ['primary-button-hover-background-color', 'primaryButtonHoverBackground'],
  ['primary-button-hover-border-color', 'primaryButtonHoverBorder'],
  ['primary-button-hover-text-color', 'primaryButtonHoverText'],

  // Login Button
  ['login-button-background-color', 'loginButtonBackground'],
  ['login-button-border-color', 'loginButtonBorder'],
  ['login-button-text-color', 'loginButtonText'],
  ['login-button-hover-background-color', 'loginButtonHoverBackground'],
  ['login-button-hover-border-color', 'loginButtonHoverBorder'],
  ['login-button-hover-text-color', 'loginButtonHoverText'],

  // Radiobutton
  ['radiobutton-selected-background-color', 'radioButtonSelectedBackground'],
  ['radiobutton-selected-border-color', 'radioButtonSelectedBorder'],

  // Other
  ['footer-background-color', 'footer'],
  ['header-background-color', 'header'],
  ['review-step-header-background-color', 'reviewStepHeader'],
  ['section-bar-background-color', 'sectionBar'],
  ['selection-background-color', 'selectionBackground'],
  ['progress-bar-color', 'progressBar'],

  // StepFooter
  ['step-footer-background-color', 'stepFooterBackground'],
  ['step-footer-border-color', 'stepFooterBorder'],
  ['step-footer-icon-color', 'stepFooterIconColor'],
];

export const STATE_NAME_TO_ABBREVIATION: { [stateName: string]: string } = {
  alabama: 'AL',
  alaska: 'AK',
  arizona: 'AZ',
  arkansas: 'AR',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  delaware: 'DE',
  florida: 'FL',
  georgia: 'GA',
  guam: 'GU',
  hawaii: 'HI',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  iowa: 'IA',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  maine: 'ME',
  maryland: 'MD',
  massachusetts: 'MA',
  michigan: 'MI',
  minnesota: 'MN',
  mississippi: 'MS',
  missouri: 'MO',
  montana: 'MT',
  nebraska: 'NE',
  nevada: 'NV',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  pennsylvania: 'PA',
  tennessee: 'TN',
  texas: 'TX',
  utah: 'UT',
  vermont: 'VT',
  virginia: 'VA',
  washington: 'WA',
  wisconsin: 'WI',
  wyoming: 'WY',

  'american samoa': 'AS',
  'district of columbia': 'DC',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  'northern mariana islands': 'MP',
  'puerto rico': 'PR',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  'virgin island': 'VI',
  'west virginia': 'WV',
};

export const STATE_AND_TERRITORY_CODES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'AS',
  'AE',
  'AP',
  'AA',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'CM',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'TT',
  'UT',
  'VT',
  'VA',
  'VI',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const RENT_OR_OWN_LABEL_MAP: Record<string, string> = {
  ownMortgage: 'Own with Mortgage',
  own: 'Own free and clear',
  rent: 'Rent',
  friend: 'With Relative/Friend',
  military: 'Military Residence',
};

/**
 * @deprecated Please use `FullAppOptimizeV1Flag.name` (i.e. the flag name made available in `featureFlags.ts`) instead of this constant
 */
export const FF_FULL_APPLICATION_V1 = 'ps-625-trident-fullapplication-optimizev1';
/**
 * @deprecated Please use `FullAppStylingFlag.name` (i.e. the flag name made available in `featureFlags.ts`) instead of this constant
 */
export const FF_FULL_APPLICATION_STYLING = 'ps-625-trident-fullapplication-new-styling';

export function isProduction(): boolean {
  return BUILD_ENV === 'production';
}

export function isTridentFunding(host: string): boolean {
  return host.includes('tridentfunding');
}

export function isBoatTrader(): boolean {
  return window.location.host.includes('boattrader');
}

export function isStage(): boolean {
  return BUILD_ENV === 'stage';
}

export function isDev(): boolean {
  return !isProduction() && !isStage();
}

export function getEnv() {
  return isProduction() ? 'prod' : 'dev';
}

/**
 * get the name of a deployed function
 * On stage this would include the hash of the last deploy so preview urls work independently
 * @param base
 */
export function getCallableName(base: string): string {
  if (!isStage()) {
    return base;
  }
  return `${base}_${BUILD_DEPLOYED_VERSION.substring(0, 7)}`;
}

/**
 * the backend url for the "old app"
 */
export function getSwipBackendUrl(): string {
  if (isProduction()) {
    return 'https://app.tridentfunding.com';
  }
  return 'https://test.tridentfunding.com';
}

export const getLoanPurposeIndex = () => {
  const experimentIndex = isProduction() ? 10 : 3;
  return experimentIndex;
};

export const CUSTOM_DATA_INDICES = {
  IS_MARINE_PURPOSE: getLoanPurposeIndex(),
};
